import { Auth0Plugin, createAuth0 } from '@auth0/auth0-vue'
import { Config } from '@/types/config'
import { getLogger } from '../logging'

const ENTITY_ID_CLAIM = 'https://thymecare.com/entity-id'

class Auth {
  private static instance: Auth | null = null
  readonly auth0: Auth0Plugin

  private constructor(private config: Config) {
    this.auth0 = createAuth0(
      {
        domain: config.auth0.domain,
        clientId: config.auth0.clientId,
        useRefreshTokens: true,
        useRefreshTokensFallback: true,
        authorizationParams: {
          redirect_uri: `${window.location.origin}/auth/callback`,
          audience: this.config.auth0.audience,
        },
        cacheLocation: 'localstorage',
      },
      // Skip this automatically, since we handle it in the AuthCallback component
      { skipRedirectCallback: true }
    )
  }

  static initialize(config: Config): Auth {
    if (!Auth.instance) {
      Auth.instance = new Auth(config)
    }
    return Auth.instance
  }

  static getInstance(): Auth {
    if (!Auth.instance) {
      throw new Error('Auth not initialized')
    }
    return Auth.instance
  }

  async getAccessToken(): Promise<string> {
    try {
      return await this.auth0.getAccessTokenSilently()
    } catch (e) {
      getLogger().error('failed to get access token', e)
      throw e
    }
  }

  getLoggedInEntityId(): string {
    const claims = this.auth0.idTokenClaims.value

    try {
      if (!claims) {
        throw Error('No claims found in Auth0 client')
      }
      const entityId = claims[ENTITY_ID_CLAIM] as string | undefined
      if (!entityId) {
        throw Error('No entity_id found in user metadata for logged in user')
      }
      return entityId
    } catch (e) {
      getLogger().error(e)
      void this.auth0.logout()
    }
    return ''
  }
}

/**
 *
 * @param config
 */
export function auth0(config: Config): Auth0Plugin {
  return Auth.initialize(config).auth0
}

/**
 *
 */
export function getAccessToken(): Promise<string> {
  return Auth.getInstance().getAccessToken()
}

/**
 *
 */
export function useLoggedInEntityId(): string {
  return Auth.getInstance().getLoggedInEntityId()
}
