import { datadogRum } from '@datadog/browser-rum'
import { MonitoringEvent } from '@/types/events'
import { getLogger } from './logging'

/**
 * Trivial wrapper around rum events so we can easily swap out
 * to a different events backend if needed.
 * @param event
 * @param commonData
 * @param commonData.entityId
 */
function sendMonitoringEvent(
  event: MonitoringEvent,
  commonData: { entityId?: string }
) {
  const data = {
    ...commonData,
    ...event.data,
  }
  getLogger().info('Sending monitoring event', event.event, data)
  datadogRum.addAction(event.event, data)
}

/**
 *
 * @param event
 */
export function sendNonLoggedInMonitoringEvent(event: MonitoringEvent) {
  sendMonitoringEvent(event, {})
}

/**
 *
 * @param event
 * @param entityId
 */
export function sendLoggedInMonitoringEvent(
  event: MonitoringEvent,
  entityId: string
) {
  sendMonitoringEvent(event, { entityId })
}

export const useEventsService = () => {
  return { sendMonitoringEvent }
}
