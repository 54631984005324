import { strapiListApiRequest } from '@/queries/cms/api'
import { Config } from '@/types/config'
import {
  Locale,
  PublicationState,
  PaginationResponseMeta,
} from '@/types/strapi'

export type ArticleInfo = {
  id: number
  attributes: {
    title: string
    slug: string
    category_sort_order: number
    resource_category?: {
      data: {
        attributes: {
          name: string
          sort_order: number
        }
      }
    }
  }
}

export type ArticlesResponse = {
  data: ArticleInfo[]
  meta: PaginationResponseMeta
}

/**
 * Gets titles, slugs, and categories of all articles
 * @param config
 */
export async function getPublishedArticles(
  config: Config
): Promise<ArticlesResponse> {
  return (await strapiListApiRequest(config, '/articles', {
    params: {
      pagination: { pageSize: 100, page: 1 },
      locale: [Locale.ENGLISH],
      publicationState: PublicationState.LIVE,
      fields: ['title', 'slug', 'category_sort_order'],
      populate: {
        resource_category: {
          fields: ['name', 'sort_order'],
        },
      },
    },
  })) as ArticlesResponse
}
