import { AccordionGroup } from '@thyme/nashville/src/components/accordion/TAccordion.vue'
import { filter } from 'lodash'
import groupBy from 'lodash/groupBy'
import reduce from 'lodash/reduce'
import { ArticleInfo } from '@/queries/cms/getPublishedArticles'

/**
 *
 * @param articles
 */
export function articlesToAccordionGroups(
  articles: ArticleInfo[]
): AccordionGroup[] {
  const articlesWithCategories = filter(
    articles,
    (article) => !!article.attributes.resource_category?.data
  )
  const categoriesToArticles = groupBy(
    articlesWithCategories,
    (article) => article.attributes.resource_category?.data.attributes.name
  )
  const accordionGroups: AccordionGroup[] = []
  // Sort categories
  const categorySortNumbers = reduce(
    categoriesToArticles,
    (acc, value, key) => {
      acc[key] =
        value[0].attributes.resource_category?.data.attributes.sort_order ??
        Number.MAX_SAFE_INTEGER
      return acc
    },
    {} as Record<string, number>
  )
  for (const category in categoriesToArticles) {
    const categoryArticles = categoriesToArticles[category]
    // Sort articles within each category
    categoryArticles.sort(
      (a, b) =>
        a.attributes.category_sort_order - b.attributes.category_sort_order
    )
    accordionGroups.push({
      title: category,
      rows: categoryArticles.map((article) => {
        return {
          title: article.attributes.title,
          link: {
            name: '#libraryPage',
            params: { resourceSlug: article.attributes.slug },
          },
        }
      }),
    } as AccordionGroup)
  }
  accordionGroups.sort(
    (a, b) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      categorySortNumbers[a.title as keyof typeof categorySortNumbers] -
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      categorySortNumbers[b.title as keyof typeof categorySortNumbers]
  )
  return accordionGroups
}
