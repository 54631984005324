<template>
  <NavPage :use-content-padding="false" :limit-max-width="false">
    <EmbeddedForm
      data-testid="symptom-id-form"
      :account-id="accountId"
      :flow-label="flowLabel"
      :variant-label="variantLabel"
      :embedded-config="embeddedConfig"
      :query-params="queryParams"
      @on-flow-finalized="showRedirectButton = true"
    />
    <router-link
      v-if="showRedirectButton"
      :to="{ path: '/' }"
      aria-label="Return to Home Page"
      class="redirect-button"
    >
      <TButton label="Done" data-cy="redirect-button" />
    </router-link>
  </NavPage>
</template>

<script lang="ts">
import TButton from '@nashville/button/TButton.vue'
import EmbeddedForm from '@nashville/EmbeddedForm/EmbeddedForm.vue'
import { defineComponent } from 'vue'
import NavPage from '@/components/tokens/NavPage/NavPage.vue'
import { setup } from './controller'

export default defineComponent({
  components: {
    EmbeddedForm,
    NavPage,
    TButton,
  },
  setup,
})
</script>
<style lang="scss" scoped>
.redirect-button {
  @apply -mt-8 mb-4 float-end;
  // float in middle of page
  // 37px is half the size of the button
  margin-right: calc(50% - 37px);
}
</style>
