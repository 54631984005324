import { iconProp } from '@thyme/nashville/src/types/icons'
import { colorProp } from '@thyme/nashville/src/types/shared'

export const MOBILE_BUTTON_PROPS = {
  ...colorProp,
  ...iconProp,
  title: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
} as const
