<template>
  <div>
    <TInputText
      name="fuzzy-search-bar"
      placeholder="Search articles..."
      :icon="searchIcon"
      autocomplete="off"
      @update:model-value="onInput"
    />
    <div v-if="suggestions.length">
      <router-link
        v-for="suggestion in suggestions"
        :key="suggestion.id"
        :to="articleLink(suggestion.slug)"
        class="no-underline"
        data-testid="search-suggestion"
      >
        <div class="suggestion-wrapper">
          <div>
            <MeiliSearchHighlight
              :option="suggestion"
              :split-marker="highlightSplitTag"
              class="font-medium"
              attribute="title"
            />
            <MeiliSearchHighlight
              :option="suggestion"
              :split-marker="highlightSplitTag"
              attribute="searchableBody"
            />
          </div>
          <TIcon name="go" icon="heroicons:arrow-right" />
        </div>
      </router-link>
    </div>
    <div v-else-if="showEmpty" class="italic pt-4">No results found.</div>
  </div>
</template>

<script lang="ts">
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import TIcon from '@nashville/icon/TIcon.vue'
import { defineComponent } from 'vue'
import { setup } from './controller'
import MeiliSearchHighlight from './MeiliSearchHighlight/MeiliSearchHighlight.vue'

export default defineComponent({
  components: {
    TInputText,
    MeiliSearchHighlight,
    TIcon,
  },
  setup,
})
</script>

<style lang="scss" scoped>
.search-bar-wrapper {
  @apply w-full;
}
</style>
<style lang="scss">
.suggestion-wrapper {
  @apply flex justify-between items-center;
  @apply px-6 py-4 my-4 rounded-lg;
  @apply bg-nash-neutral000 text-nash-midnightBlue700;
  @apply border border-nash-neutral400;
}
</style>
