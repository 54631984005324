import { RouteLocationNormalized } from 'vue-router'

const DEFAULT_PAGE_TITLE = 'Thyme Care Connect'

/**
 * Set the document title to the title of the route on change
 * @param to
 */
export function pageTitleHook(to: RouteLocationNormalized) {
  document.title = (to?.meta?.title as string) ?? DEFAULT_PAGE_TITLE
}
