import { IconifyIcons } from '@thyme/nashville/src/types/icons'
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import { ref } from 'vue'
import { sendLoggedInMonitoringEvent } from '@/libs/events'
import { useLoggedInEntityId } from '@/libs/state/auth0Client'
import {
  EXTERNAL_URL_COMMUNITY_RESOURCES,
  THYME_EMAIL,
  THYME_PHONE_NUMBER,
  THYME_PHONE_NUMBER_DISPLAY,
} from '@/libs/thymeInfo'
import { MonitoringEventName } from '@/types/events'
import { schema, ZIP_CODE_FIELD_ID } from './types'

/**
 *
 */
export function setup() {
  const zipCode = ref('')
  const entityId = useLoggedInEntityId()

  const { handleSubmit } = useForm({
    initialValues: {
      [ZIP_CODE_FIELD_ID]: '',
    },
    validationSchema: toTypedSchema(schema),
  })

  const onSubmit = handleSubmit((values) => {
    sendLoggedInMonitoringEvent(
      {
        event: MonitoringEventName.COMMUNITY_RESOURCES_SEARCH,
        data: {
          zipCode: values['zip-code'],
        },
      },
      entityId
    )
    window.location.href = `${EXTERNAL_URL_COMMUNITY_RESOURCES}/search_results/${values['zip-code']}`
  })

  return {
    zipCode,
    onSubmit,
    // passthrough
    THYME_EMAIL,
    THYME_PHONE_NUMBER,
    THYME_PHONE_NUMBER_DISPLAY,
    ZIP_CODE_FIELD_ID,
    IconifyIcons,
  }
}
