<template>
  <!-- Include our own class here, t-accordion, so styles aren't applied to PV Accordion when not using this component -->
  <Accordion
    :active-index="-1"
    :data-cy="name && `${kebabCase(name)}-accordion`"
    class="t-accordion"
  >
    <AccordionTab
      v-for="group in groups"
      :key="group.title"
      :header="group.title"
    >
      <div
        v-for="row in group.rows"
        :key="row.title"
        class="text-base text-nash-midnightBlue700 border-b-2 pb-6 border-solid border-nash-midnightBlue100 flex last:border-none last:pb-0"
      >
        <RouterLink
          v-if="row.link"
          :to="row.link"
          class="no-underline w-full text-nash-midnightBlue700 hover:text-nash-midnightBlue700"
        >
          <div class="flex flex-row w-full justify-between items-center">
            <span>{{ row.title }}</span>
            <TIcon class="inline" icon="heroicons:arrow-right" />
          </div>
        </RouterLink>
        <span v-else>{{ row.title }}</span>
      </div>
    </AccordionTab>
  </Accordion>
</template>

<script lang="ts">
import kebabCase from 'lodash/kebabCase'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import { PropType, defineComponent } from 'vue'
import { RouterLink, RouteLocationRaw } from 'vue-router'
import TIcon from '../icon/TIcon.vue'

export type AccordionRow = {
  link?: RouteLocationRaw
  title: string
}

export type AccordionGroup = {
  title: string
  rows: AccordionRow[]
}

export default defineComponent({
  components: {
    Accordion,
    AccordionTab,
    TIcon,
    RouterLink,
  },
  props: {
    /** for test attr naming */
    name: {
      type: String,
      default: null,
    },
    /** values for the accordion */
    groups: {
      type: Array as PropType<AccordionGroup[]>,
      required: true,
    },
  },
  setup() {
    return { kebabCase }
  },
})
</script>
