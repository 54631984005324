export const DEFAULT_LOGO_WIDTH = 100

export const NAV_BAR_PROPS = {
  logo: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  url: {
    type: String,
    required: true,
  },
  logoWidth: {
    type: Number,
    default: DEFAULT_LOGO_WIDTH,
  },
  additionalClasses: {
    type: Array,
    default: () => [],
  },
} as const
