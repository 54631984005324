<template>
  <NavPage>
    <div class="text-center">
      <h1 id="error-header" class="text-nash-purple600 block">
        An unexpected error occurred.
      </h1>
      <nav class="mt-2">
        <RouterLink id="home-link" to="/">Go Home</RouterLink>
      </nav>
    </div>
  </NavPage>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { RouterLink } from 'vue-router'
import NavPage from '@/components/tokens/NavPage/NavPage.vue'

export default defineComponent({
  components: {
    NavPage,
    RouterLink,
  },
})
</script>
