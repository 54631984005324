import { apiRequest } from '@thyme/libs/src/api/apiRequest'
import { Method, RequestOptions } from '@thyme/libs/src/api/types/api'
import { Config } from '@/types/config'
import { StrapiListRequestOptions } from '@/types/strapi'

/**
 *
 * @param config
 * @param method
 * @param endpoint
 * @param options
 */
export async function _strapiApiRequest(
  config: Config,
  method: Method,
  endpoint: string,
  options: RequestOptions
): Promise<any> {
  if (!options.headers) {
    options.headers = {}
  }

  options.headers.Authorization = `Bearer ${config.strapi.apiKey}`
  // Cache-control isn't supported by the Strapi API
  options.noCache = true

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return await apiRequest(method, endpoint, options, config.strapi.apiUrl)
}

/**
 *
 * @param config
 * @param endpoint
 * @param options
 */
export async function strapiListApiRequest(
  config: Config,
  endpoint: string,
  options: StrapiListRequestOptions
): Promise<any> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return await _strapiApiRequest(config, 'GET', endpoint, options)
}
