import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { NavBarInfo } from '@/types/generic/nav'
import { ALL_PRACTICES } from '@/types/practices'
import type { ComputedRef } from 'vue'

export const AON_THEME_CLASSES = ['bg-nash-neutral000', 'text-nash-neutral800']

/**
 *
 */
export function setup(): {
  practiceMap: ComputedRef<NavBarInfo>
  AON_THEME_CLASSES: string[]
} {
  const route = useRoute()

  // Whitelabel page based on practice information in query params
  const practice = computed(() => {
    return (route?.query?.practice as keyof typeof ALL_PRACTICES) ?? 'tcAon'
  })

  // Provide nav bar information based on practice
  const practiceMap = computed(
    () => ALL_PRACTICES[practice.value] ?? ALL_PRACTICES.tcAon
  )

  return {
    practiceMap,
    AON_THEME_CLASSES,
  }
}
