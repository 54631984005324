<template>
  <footer class="flex justify-center">
    <div
      class="gap-y-5 p-6 grid max-w-3xl md:w-3xl text-base text-nash-purple500"
    >
      <div
        v-for="button in buttons"
        :key="button.title"
        class="border-b-1 pb-6 border-solid border-nash-purple200"
        data-cy="main-footer-button"
      >
        <NavFooterButton :title="button.title" :link="button" />
      </div>
      <!-- We can't use TAccordion here since it's not styled in the same way,
       so we have to use passthroughs for the bit of styling to get it to match the 
       rest of the footer. -->
      <Accordion
        v-if="showAuthedPages"
        class="footer-accordion"
        :active-index="-1"
        data-cy="sitemap"
      >
        <AccordionTab
          header="Show Sitemap"
          :pt="{
            headerAction: { class: 'no-underline' },
            headerTitle: {
              class: 'no-underline font-medium text-nash-purple500',
            },
            headerIcon: {
              class:
                'absolute right-1 top-2/4 -translate-y-2/4 text-nash-purple500',
            },
          }"
        >
          <div
            v-for="button in sitemap"
            :key="button.title"
            class="ml-6 border-b-1 py-6 border-solid border-nash-purple200 last:border-none last:pb-0"
          >
            <NavFooterButton :title="button.title" :link="button" />
            <div v-if="button.showResources" data-cy="sitemap-resources">
              <div
                v-for="resourceGroup in resources"
                :key="resourceGroup.title"
                class="text-sm ml-6 py-3"
                data-cy="footer-resource-group"
              >
                <h4 class="text-sm font-normal text-base text-nash-purple400">
                  {{ resourceGroup.title }}
                </h4>
                <TOmniLink
                  v-for="resource in resourceGroup.rows"
                  :key="resource.title"
                  :link="{ to: resource.link }"
                >
                  <span>{{ resource.title }}</span>
                </TOmniLink>
              </div>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  </footer>
</template>

<script lang="ts">
import TOmniLink from '@nashville/omnilink/TOmniLink.vue'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import { defineComponent } from 'vue'
import { setup } from './controller'
import NavFooterButton from './NavFooterButton/NavFooterButton.vue'
import { TO_FOOTER_PROPS } from './types'

export default defineComponent({
  components: {
    NavFooterButton,
    Accordion,
    AccordionTab,
    TOmniLink,
  },
  props: TO_FOOTER_PROPS,
  setup,
})
</script>

<style lang="scss" scoped>
a {
  @apply underline;
}
.footer-accordion {
  @apply text-base text-nash-purple500;
}
.footer-accordion h4 {
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
}
</style>
