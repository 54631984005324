import * as t from 'io-ts'
import type { InjectionKey } from 'vue'

const EnvDecoder = t.keyof({ dev: null, staging: null, prod: null, beta: null })

export const ConfigDecoder = t.type({
  env: EnvDecoder,
  auth0: t.type({
    domain: t.string,
    clientId: t.string,
    audience: t.string,
  }),
  strapi: t.type({
    apiUrl: t.string,
    apiKey: t.string,
  }),
  splitApiKey: t.string,
  datadog: t.type({
    clientToken: t.string,
    applicationId: t.string,
    site: t.string,
    service: t.string,
    env: t.string,
    enabled: t.boolean,
  }),
  meiliSearch: t.type({
    url: t.string,
    apiKey: t.string,
  }),
  apiUrl: t.string,
})

export type Config = t.TypeOf<typeof ConfigDecoder>
export type ConnectEnv = t.TypeOf<typeof EnvDecoder>

// https://vuejs.org/guide/typescript/composition-api#typing-provide-inject
export const APP_CONFIG_KEY = Symbol('APP_CONFIG_KEY') as InjectionKey<Config>
