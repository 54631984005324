import { useQuery } from '@tanstack/vue-query'
import { hoursToMs } from '@thyme/libs/src/time/convert'
import { requiredInject } from '@thyme/libs/src/vue/inject'
import { AccordionGroup } from '@thyme/nashville/src/components/accordion/TAccordion.vue'
import { IconPosition } from '@thyme/nashville/src/types/icons'
import { OmniLink } from '@thyme/nashville/src/types/omnilinks'
import { computed } from 'vue'
import { articlesToAccordionGroups } from '@/libs/strapi/articles'
import {
  THYME_PHONE_NUMBER_DISPLAY,
  EXTERNAL_URL_ABOUT_THYME,
  EXTERNAL_URL_PRIVACY_POLICY,
  THYME_PHONE_NUMBER,
} from '@/libs/thymeInfo'
import { getPublishedArticles } from '@/queries/cms/getPublishedArticles'
import { APP_CONFIG_KEY } from '@/types/config'
import { TO_FOOTER_PROPS } from './types'
import type { ExtractPropTypes, Ref } from 'vue'

type FooterButton = {
  title: string
} & OmniLink

type SitemapButton = {
  showResources?: boolean
} & FooterButton

type FooterProps = ExtractPropTypes<typeof TO_FOOTER_PROPS>

/**
 *
 * @param props
 * @param props.showAuthedPages
 */
export function setup(props: FooterProps): {
  resources: Ref<AccordionGroup[]>
  buttons: FooterButton[]
  sitemap: SitemapButton[]
  IconPosition: typeof IconPosition
} {
  const config = requiredInject(APP_CONFIG_KEY)
  const { data: articles } = useQuery({
    queryKey: ['publishedArticles'],
    queryFn: () => getPublishedArticles(config),
    staleTime: hoursToMs(1),
    retry: false,
    select: (response) => response.data,
  })

  const buttons: FooterButton[] = [
    ...(props.showAuthedPages
      ? [
          {
            title: 'Home',
            to: { name: '#home' },
          },
        ]
      : []),
    {
      title: `Get support: call or text ${THYME_PHONE_NUMBER_DISPLAY}`,
      ...(props.showAuthedPages
        ? { to: { name: '#support' } }
        : { href: `tel:${THYME_PHONE_NUMBER}` }),
    },
    {
      title: 'About',
      href: EXTERNAL_URL_ABOUT_THYME,
    },
    {
      title: 'Legal Terms & Privacy',
      href: EXTERNAL_URL_PRIVACY_POLICY,
    },
  ]
  const sitemap: SitemapButton[] = [
    {
      title: 'Track Symptoms',
      to: { name: '#symptoms' },
    },
    {
      title: 'Library',
      to: { name: '#library' },
      showResources: true,
    },
    {
      title: 'Community Resources',
      to: { name: '#communityResources' },
    },
  ]
  const resources = computed(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return articlesToAccordionGroups(articles.value ?? [])
  })

  return {
    buttons,
    sitemap,
    IconPosition,
    resources,
  }
}
