import { IEventMap, SupportedAnalyticsEvent } from '@formsort/web-embed-api'

export enum EventEmits {
  onUnauthorized = 'onUnauthorized',
  onRedirect = 'onRedirect',
  onFlowLoaded = 'onFlowLoaded',
  onFlowClosed = 'onFlowClosed',
  onFlowFinalized = 'onFlowFinalized',
  onStepLoaded = 'onStepLoaded',
  onStepCompleted = 'onStepCompleted',
}

export const EventTriggers: Array<[keyof IEventMap, EventEmits]> = [
  [SupportedAnalyticsEvent.FlowLoaded, EventEmits.onFlowLoaded],
  [SupportedAnalyticsEvent.FlowClosed, EventEmits.onFlowClosed],
  [SupportedAnalyticsEvent.FlowFinalized, EventEmits.onFlowFinalized],
  [SupportedAnalyticsEvent.StepLoaded, EventEmits.onStepLoaded],
  [SupportedAnalyticsEvent.StepCompleted, EventEmits.onStepCompleted],
  ['unauthorized', EventEmits.onUnauthorized],
  ['redirect', EventEmits.onRedirect],
]
