<template>
  <div class="loading-screen-cover">
    <div id="loading">
      <img
        src="/assets/images/Thyme-Logo-Wordmark-OffWhite.png"
        alt="loading"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loading-screen-cover {
  @apply flex items-center content-center justify-center;
  @apply h-screen w-screen fixed top-0 left-0 z-50;
  @apply bg-nash-purple600;
}

#loading img {
  animation: 0.8s ease-in-out 0s infinite alternate breathe;
  transition: opacity 1s;
  width: 100px;
}

@keyframes breathe {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0.9);
  }
}
</style>
