<template>
  <NavPage>
    <div class="text-left">
      <h1 id="resources-header" class="text-nash-purple600 block">Library</h1>
      <p id="description-text" class="mt-2 mb-3">
        Learn about your diagnosis, treatment, wellness, and more. We're here to
        help.
      </p>
      <MeiliInstantSearch v-if="showLibrarySearch" />
      <article class="mt-5">
        <TAccordion name="resource-accordion" :groups="resources" />
        <p class="italic mt-3">
          All Thyme Care educational content has been reviewed by our oncology
          team and is intended to provide helpful information for people with
          cancer and their loved ones. It is not medical advice. Talk to your
          doctor or your Thyme Care nurse about medical questions so they can
          give you personalized guidance.
        </p>
      </article>
    </div>
  </NavPage>
</template>

<script lang="ts">
import TAccordion from '@thyme/nashville/src/components/accordion/TAccordion.vue'
import { defineComponent } from 'vue'
import MeiliInstantSearch from '@/components/tokens/MeiliInstantSearch/MeiliInstantSearch.vue'
import NavPage from '@/components/tokens/NavPage/NavPage.vue'
import { setup } from './controller'

export default defineComponent({
  components: {
    NavPage,
    TAccordion,
    MeiliInstantSearch,
  },
  setup,
})
</script>
