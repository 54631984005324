import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'
import { Config } from '@/types/config'
import {
  SearchClient,
  SearchQuery,
  SearchResponse,
  SearchResult,
} from './types'

// Store Search client locally so we don't have to recreate it every time
let searchClient: SearchClient | null = null

/**
 * Search client for MeiliSearch instance
 * @param config
 */
export function getSearchClient(config: Config) {
  if (!searchClient) {
    const url = config.meiliSearch.url
    const apiKey = config.meiliSearch.apiKey

    searchClient = instantMeiliSearch(url, apiKey, {
      placeholderSearch: false,
      primaryKey: 'id',
      meiliSearchParams: {
        attributesToCrop: ['searchableBody'],
        cropLength: 10,
      },
    }).searchClient as SearchClient
  }

  return searchClient
}

/**
 * get results from the search client
 * @param config
 * @param query
 * @param indexName
 * @param highlightSplitTag
 */
export async function getResults(
  config: Config,
  query: string,
  indexName: string,
  highlightSplitTag: string
): Promise<SearchResult[]> {
  const payload: SearchQuery[] = [
    {
      indexName,
      params: {
        analytics: false,
        'attributes-to-snippet': ['searchableBody'],
        distinct: true,
        enablePersonalization: true,
        highlightPostTag: highlightSplitTag,
        highlightPreTag: highlightSplitTag,
        hitsPerPage: 10,
        query: query,
      },
    },
  ]

  // @ts-ignore - hits do exist
  const res: SearchResponse = await getSearchClient(config).search(payload)
  return res.results[0].hits
}
