<template>
  <TOmniLink :link="link" :classes="['no-underline']">
    <MobileButton v-bind="$attrs" />
  </TOmniLink>
</template>

<script lang="ts">
import TOmniLink from '@nashville/omnilink/TOmniLink.vue'
import { defineComponent } from 'vue'
import MobileButton from '../MobileButton/MobileButton.vue'
import { TO_LINKED_MOBILE_BUTTON_PROPS } from './types'

export default defineComponent({
  components: {
    MobileButton,
    TOmniLink,
  },
  inheritAttrs: false,
  props: TO_LINKED_MOBILE_BUTTON_PROPS,
})
</script>
