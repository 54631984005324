import { apiRequest as _apiRequest } from '@thyme/libs/src/api/apiRequest'
import { Method, RequestOptions } from '@thyme/libs/src/api/types/api'
import { getAccessToken } from '@/libs/state/auth0Client'
import { getAppConfig } from './config'

/**
 * Makes authenticated call to the Connect BFF.
 * Must only be used in an already authenticated context.
 * @param method
 * @param endpoint
 * @param options
 */
export async function apiRequest<R>(
  method: Method,
  endpoint: string,
  options: RequestOptions = {}
): Promise<R> {
  if (!options.headers) {
    options.headers = {}
  }

  const accessToken = await getAccessToken()

  options.headers.Authorization = `Bearer ${accessToken}`

  return (await _apiRequest(
    method,
    endpoint,
    options,
    getAppConfig().apiUrl
  )) as R
}
