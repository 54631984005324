import { useAuth0 } from '@auth0/auth0-vue'

/**
 *
 */
export function setup() {
  const auth0Client = useAuth0()

  /**
   *
   */
  async function logout() {
    await auth0Client.logout()
  }

  return {
    logout,
    showLogOut: auth0Client.isAuthenticated,
  }
}
