import FormsortWebEmbed, { IFormsortWebEmbed } from '@formsort/web-embed-api'
import { EventEmits, EventTriggers } from '@thyme/nashville/src/types/formsort'
import { ref, onMounted, onBeforeUnmount } from 'vue'

/**
 * @param props
 * @param props.accountId
 * @param props.flowLabel
 * @param props.variantLabel
 * @param props.queryParams
 * @param props.embeddedConfig
 * @param context
 * @param context.emit
 */
export default function (
  props: {
    accountId: string
    flowLabel: string
    variantLabel: string
    queryParams: [string, string][]
    embeddedConfig: object
  },
  context: { emit: (event: EventEmits, ...args: any[]) => void }
) {
  const formsortContainer = ref<HTMLDivElement | null>(null)
  const embeddedFormsort = ref<IFormsortWebEmbed | null>(null)

  /**
   *
   */
  function initFormsort() {
    if (!formsortContainer.value) {
      console.error(
        'Formsort container not found, cannot initialize Formsort embedded form'
      )
      return
    }

    embeddedFormsort.value = FormsortWebEmbed(
      formsortContainer.value,
      props.embeddedConfig
    )

    EventTriggers.forEach(([formsortEvent, triggeredEvent]) => {
      embeddedFormsort.value?.addEventListener(formsortEvent, () => {
        context.emit(triggeredEvent)
      })
    })

    formsortContainer.value.getElementsByTagName('iframe')[0].title =
      'Formsort Embedded Form'
  }

  /**
   *
   */
  function loadFlow() {
    if (embeddedFormsort.value) {
      embeddedFormsort.value.loadFlow(
        props.accountId,
        props.flowLabel,
        props.variantLabel,
        props.queryParams.length ? props.queryParams : undefined
      )
    }
  }

  onMounted(() => {
    initFormsort()
    loadFlow()
  })

  onBeforeUnmount(() => {
    if (embeddedFormsort.value) {
      embeddedFormsort.value.unloadFlow()
    }
  })

  return {
    formsortContainer,
    // passed for tests
    embeddedFormsort,
    initFormsort,
    loadFlow,
  }
}
