<template>
  <div class="app-wrapper">
    <NavBar
      :logo="navBarInfo.logo"
      :name="navBarInfo.name"
      :url="navBarInfo.url"
      :logo-width="navBarInfo.logoWidth"
      :additional-classes="headerClasses"
    />
    <div id="content-wrapper">
      <main class="justify-center flex" :class="mainPaddingClasses">
        <div :class="limitedWidthClasses">
          <slot></slot>
        </div>
      </main>
      <NavFooter :show-authed-pages="showAuthedPages" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import NavBar from '@/components/tokens/NavBar/NavBar.vue'
import NavFooter from '@/components/tokens/NavFooter/NavFooter.vue'
import { setup } from './controller'
import { NAV_PAGE_PROPS } from './types'

export default defineComponent({
  components: {
    NavBar,
    NavFooter,
  },
  props: NAV_PAGE_PROPS,
  setup,
})
</script>

<style lang="scss" scoped>
.app-wrapper {
  @apply w-screen;
  @apply flex flex-col justify-items-stretch;
  // Old Browsers & Firefox
  height: 100vh;
  // Modern Browsers & Mobile
  // Fixes browser height issue on mobile
  // https://stackoverflow.com/a/70048720
  height: 100dvh;
}
#content-wrapper {
  @apply space-y-6;
  @apply overflow-auto grow;
}

.content-padding {
  @apply px-6 py-9;
}
</style>
