import { RequestOptions } from '@thyme/libs/src/api/types/api'

// Enums
export enum PublicationState {
  LIVE = 'live',
  DRAFT = 'draft',
}

export enum Locale {
  ENGLISH = 'en',
  SPANISH = 'es',
}

export type PaginationParams = {
  pageSize: number
  page: number
}

export type StrapiListRequestParams = {
  sort?: string[]
  populate?: {
    [key: string]: { fields: string[] }
  }
  fields: string[]
  filters?: { [key: string]: { [key: string]: any } }
  pagination: PaginationParams
  publicationState: PublicationState
  locale: Locale[]
}

export type StrapiListRequestOptions = RequestOptions & {
  params: StrapiListRequestParams
}

// Response types

export type PaginationResponseMeta = {
  pagination: {
    pageSize: number
    page: number
    pageCount: number
    total: number
  }
}

export type ErrorResponse = {
  status: number
  name: string
  message: string
  details: { [key: string]: any }
}
