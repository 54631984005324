import { OmniLink } from '@thyme/nashville/src/types/omnilinks'
import type { PropType } from 'vue'

export type LinkedMobileButtonAttrs = {
  color: string
  title: string
  description: string
  icon: string
  link: OmniLink
}

export const TO_LINKED_MOBILE_BUTTON_PROPS = {
  link: {
    type: Object as PropType<OmniLink>,
    required: true,
  },
} as const
