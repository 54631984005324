import { useQuery } from '@tanstack/vue-query'
import { storeToRefs } from 'pinia'
import { apiRequest } from '@/libs/api'
import { useFlagStore } from '@/libs/flags'

type EntityMeResponse = {
  entityId: string
}
/**
 *
 */
async function fetchWhoAmI(): Promise<EntityMeResponse> {
  return await apiRequest('GET', '/entities/me')
}

/**
 *
 * @param entityId
 */
export function useWhoAmI(entityId: string) {
  const { shouldHitBackend } = storeToRefs(useFlagStore())
  return useQuery({
    queryKey: ['entities', 'me', entityId],
    queryFn: fetchWhoAmI,
    enabled: () => shouldHitBackend.value,
    retry: false,
  })
}
