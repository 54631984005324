import { initDatadogLogs } from '@thyme/libs/src/datadog/logs'
import { initDatadogRum } from '@thyme/libs/src/datadog/rum'
import { getLogger } from '@/libs/logging'
import { Config } from '@/types/config'

/**
 *
 * @param config
 */
export function initializeDatadog(config: Config) {
  if (!config.datadog.enabled) {
    getLogger().debug('Skipping Datadog initialization...', { foo: 'bar' })
    return
  }

  initDatadogLogs(config.datadog, {
    version: APP_VERSION,
    forwardConsoleLogs: 'all',
    sessionSampleRate: 100,
  })

  initDatadogRum(config.datadog, {
    version: APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    defaultPrivacyLevel: 'allow',
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    enableExperimentalFeatures: ['clickmap'],
  })

  getLogger().info('Datadog initialized.')
}
