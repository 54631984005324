<template>
  <div>
    <span v-for="(labelPart, index) in splitLabel" :key="index">
      <span v-if="index % 2 === 0">{{ labelPart }}</span>
      <span v-else class="underline">{{ labelPart }}</span>
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { setup } from './controller'
import { SEARCH_HIGHLIGHT_PROPS } from './types'

export default defineComponent({
  props: SEARCH_HIGHLIGHT_PROPS,
  setup,
})
</script>
