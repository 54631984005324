import { computed, type ExtractPropTypes } from 'vue'
import { NAV_PAGE_PROPS } from './types'

type NavPagePropType = ExtractPropTypes<typeof NAV_PAGE_PROPS>

/**
 *
 * @param props
 */
export function setup(props: NavPagePropType) {
  const limitedWidthClasses = computed(() =>
    props.limitMaxWidth ? 'max-w-3xl md:w-3xl' : 'w-full'
  )

  const mainPaddingClasses = computed(() => [
    props.useContentPadding ? 'content-padding' : '',
    ...props.bodyClasses,
  ])

  return {
    limitedWidthClasses,
    mainPaddingClasses,
  }
}
