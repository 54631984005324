<template>
  <NavPage
    :use-content-padding="false"
    :limit-max-width="false"
    :show-authed-pages="false"
  >
    <EmbeddedForm
      data-testid="login-help-form"
      :account-id="accountId"
      :flow-label="flowLabel"
      :variant-label="variantLabel"
      :embedded-config="embeddedConfig"
      :query-params="queryParams"
    />
  </NavPage>
</template>

<script lang="ts">
import EmbeddedForm from '@nashville/EmbeddedForm/EmbeddedForm.vue'
import { defineComponent } from 'vue'
import NavPage from '@/components/tokens/NavPage/NavPage.vue'
import { setup } from './controller'

export default defineComponent({
  components: {
    EmbeddedForm,
    NavPage,
  },
  setup,
})
</script>
