<template>
  <div>
    <!-- Don't use inline styles here so they're more easily overridable -->
    <a v-if="link.href" :href="link.href" :class="[...classes, 't-omnilink']">
      <slot></slot>
    </a>
    <RouterLink
      v-else-if="link.to"
      :to="link.to"
      :class="[...classes, 't-omnilink']"
    >
      <slot></slot>
    </RouterLink>
  </div>
</template>

<script lang="ts">
import { OmniLink } from '@thyme/nashville/src/types/omnilinks'
import { defineComponent } from 'vue'
import { RouterLink } from 'vue-router'
import type { PropType } from 'vue'

export default defineComponent({
  components: {
    RouterLink,
  },
  inheritAttrs: false,
  props: {
    link: {
      type: Object as PropType<OmniLink>,
      required: true,
    },
    classes: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
})
</script>
