import { authGuard, useAuth0 } from '@auth0/auth0-vue'
import { unref } from 'vue'
import { RouteLocationNormalized } from 'vue-router'
import { sendLoggedInMonitoringEvent } from '@/libs/events'
import { getLogger } from '@/libs/logging'
import { useLoggedInEntityId } from '@/libs/state/auth0Client'
import { MonitoringEventName } from '@/types/events'

export const AUTH_CALLBACK_NAME = '#authCallback'

/**
 *
 * @param to
 */
function sendTrackingEvent(to: RouteLocationNormalized) {
  try {
    const entityId = useLoggedInEntityId()
    sendLoggedInMonitoringEvent(
      { event: MonitoringEventName.LOGIN, data: { target: to.fullPath } },
      entityId
    )
  } catch (e: any) {
    getLogger().error('Error sending monitoring event', e)
  }
}
/**
 * Add an auth guard unless the route is marked as publicly accessible
 * @param to
 * @param from
 */
export async function maybeAuthGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) {
  if (to.meta.noAuthRequired) {
    return true
  } else {
    const client = useAuth0()
    if (!unref(client.isAuthenticated)) {
      // Try to silently auth if we can
      await client.checkSession()
    }
    if (from.name === AUTH_CALLBACK_NAME) {
      sendTrackingEvent(to)
    }
    // Call the auth guard, which will redirect to the login page if necessary
    await authGuard(to)
    return true
  }
}
