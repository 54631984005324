import { requiredInject } from '@thyme/libs/src/vue/inject'
import { debounce } from 'lodash'
import { computed, ref } from 'vue'
import { APP_CONFIG_KEY } from '@/types/config'
import { getResults } from './queries'
import { SearchResult } from './types'

/**
 *
 */
export function setup() {
  const config = requiredInject(APP_CONFIG_KEY)

  const indexName = 'article'
  const highlightSplitTag = '__THYME-HIGHLIGHT__'

  const suggestions = ref<SearchResult[]>([])
  const isLoading = ref(false)

  const searchIcon = computed(() => (isLoading.value ? 'loading' : 'search'))

  // used to differentiate no search from no results
  const currentQuery = ref('')

  // show "no results found" message
  const showEmpty = computed(
    () => !isLoading.value && !suggestions.value.length && !!currentQuery.value
  )

  const update = async (query: string) => {
    suggestions.value = await getResults(
      config,
      query,
      indexName,
      highlightSplitTag
    )
    isLoading.value = false
  }
  const USER_INPUT_WAIT_TIME = 200
  const debouncedUpdate = debounce(update, USER_INPUT_WAIT_TIME)

  const onInput = (query: string) => {
    isLoading.value = true
    currentQuery.value = query
    void debouncedUpdate(query)
  }

  /**
   *
   * @param resourceSlug
   */
  function articleLink(resourceSlug: string) {
    return {
      name: '#libraryPage',
      params: { resourceSlug },
    }
  }

  return {
    searchIcon,
    indexName,
    highlightSplitTag,
    suggestions,
    showEmpty,
    onInput,
    articleLink,
    // for tests only
    currentQuery,
    debouncedUpdate,
  }
}
