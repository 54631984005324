import {
  DEFAULT_EMBEDDED_CONFIG,
  FORMSORT_ACCOUNT_ID,
  defaultQueryParams,
} from '@/libs/formsort'

/**
 *
 */
export function setup(): {
  accountId: string
  flowLabel: string
  variantLabel: string
  embeddedConfig: object
  queryParams: Array<[string, string]>
} {
  const FLOW_LABEL = 'tc-connect-login-help'
  const VARIANT_LABEL = 'main'

  return {
    accountId: FORMSORT_ACCOUNT_ID,
    flowLabel: FLOW_LABEL,
    variantLabel: VARIANT_LABEL,
    embeddedConfig: DEFAULT_EMBEDDED_CONFIG,
    queryParams: defaultQueryParams(),
  }
}
