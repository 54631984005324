import { inject } from 'vue'
import { APP_CONFIG_KEY, ConnectEnv } from '@/types/config'

export const FORMSORT_ACCOUNT_ID = 'GVGy7e-KFn'

export const DEFAULT_EMBEDDED_CONFIG = {
  autoHeight: true,
  style: { width: '100%' },
}

const authEnvToFormsortEnv: Record<ConnectEnv, string> = {
  dev: 'staging',
  staging: 'staging',
  prod: 'production',
  beta: 'production',
}

/**
 *
 */
export function getFormsortEnvParam(): [string, string] {
  const config = inject(APP_CONFIG_KEY)

  const env = authEnvToFormsortEnv[config?.env ?? 'prod']
  return ['formsortEnv', env]
}

/**
 *
 */
export function defaultQueryParams(): [string, string][] {
  return [getFormsortEnvParam()]
}
