import { useQuery } from '@tanstack/vue-query'
import { hoursToMs } from '@thyme/libs/src/time/convert'
import { requiredInject } from '@thyme/libs/src/vue/inject'
import { AccordionGroup } from '@thyme/nashville/src/components/accordion/TAccordion.vue'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useFlagStore } from '@/libs/flags'
import { articlesToAccordionGroups } from '@/libs/strapi/articles'
import { getPublishedArticles } from '@/queries/cms/getPublishedArticles'
import { APP_CONFIG_KEY } from '@/types/config'
import type { Ref } from 'vue'

/**
 *
 */
export function setup(): {
  showLibrarySearch: Ref<boolean>
  resources: Ref<AccordionGroup[]>
} {
  const config = requiredInject(APP_CONFIG_KEY)
  const { showLibrarySearch } = storeToRefs(useFlagStore())

  const { data: articles } = useQuery({
    queryKey: ['publishedArticles'],
    queryFn: () => getPublishedArticles(config),
    staleTime: hoursToMs(1),
    retry: false,
    select: (response) => response.data,
  })

  const resources = computed(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return articlesToAccordionGroups(articles.value ?? [])
  })

  return {
    showLibrarySearch,
    resources,
  }
}
