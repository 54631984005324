import { NavBarInfo } from '@/types/generic/nav'

export type PracticeMap = {
  [key: string]: NavBarInfo
}

export const AON_LOGO_WIDTH = 200

const AON_PRACTICE_MAP: PracticeMap = {
  cbsa: {
    name: 'Cancer & Blood Specialists of Arizona',
    logo: '/assets/images/aon/cbsa.png',
    url: 'https://www.cancerbloodspecialistsaz.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  cbsg: {
    name: 'Cancer & Blood Specialists of Georgia',
    logo: '/assets/images/aon/cbsg.png',
    url: 'https://www.cancerbloodspecialistsga.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  cbsn: {
    name: 'Cancer & Blood Specialists of North Virginia',
    logo: '/assets/images/aon/cbsn.png',
    url: 'https://cancerandbloodspecialistsofnova.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  dho: {
    name: 'Desert Hematology Oncology',
    logo: '/assets/images/aon/dho.png',
    url: 'https://www.deserthematologyoncology.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  gcc: {
    name: 'Genesis Cancer & Blood Institute',
    logo: '/assets/images/aon/gcc.png',
    url: 'https://genesiscancerblood.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  gohc: {
    name: 'Georgia Oncology Hematology Consultants',
    logo: '/assets/images/aon/gohc.png',
    url: 'https://gaoncologyhematology.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  gscbs: {
    name: 'Gem State Cancer & Blood Specialists',
    logo: '/assets/images/aon/gscbs.png',
    url: 'https://www.gemstatecancerbloodspecialists.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  hccn: {
    name: 'Hematology & Cancer Center of Nevada',
    logo: '/assets/images/aon/hccn.png',
    url: 'https://www.hccnevada.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  hoc: {
    name: 'Hematology Oncology Center',
    logo: '/assets/images/aon/hoc.png',
    url: 'https://elyriahoc.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  hemonc: {
    name: 'Hematology Oncology',
    logo: '/assets/images/aon/hemonc.png',
    url: 'https://www.aoncology.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  hoh: {
    name: 'Heartland Oncology & Hematology',
    logo: '/assets/images/aon/hoh.png',
    url: 'https://www.heartlandoncology.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  hoi: {
    name: 'Hematology Oncology of Indiana',
    logo: '/assets/images/aon/hoi.png',
    url: 'https://www.hematologyoncologyin.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  lccc: {
    name: 'Low Country Cancer Care',
    logo: '/assets/images/aon/lccc.png',
    url: 'https://www.lcccsav.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  mcc: {
    name: 'Messino Cancer Centers',
    logo: '/assets/images/aon/mcc.png',
    url: 'https://messinocancercenters.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  micc: {
    name: 'Michigan Cancer Specialists',
    logo: '/assets/images/aon/micc.png',
    url: 'https://www.micancerspecialists.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  oha: {
    name: 'Oncology Hematology Associates',
    logo: '/assets/images/aon/oha.png',
    url: 'https://ohaclinic.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  ohl: {
    name: 'Oncology Hematology of Loudoun',
    logo: '/assets/images/aon/ohl.png',
    url: 'https://loudounoncology.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  pano: {
    name: 'Panacea Oncology',
    logo: '/assets/images/aon/pano.png',
    url: 'https://www.panaceaoncology.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  scc: {
    name: 'Summit Cancer Centers',
    logo: '/assets/images/aon/scc.png',
    url: 'https://www.summitcancercenters.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  vista: {
    name: 'Vista Oncology',
    logo: '/assets/images/aon/vista.png',
    url: 'https://www.vista-oncology.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  zcc: {
    name: 'Zang Cancer Center',
    logo: '/assets/images/aon/zcc.png',
    url: 'https://www.zangcenter.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  ccbd: {
    name: 'Comprehensive Cancer & Blood Disorders',
    logo: '/assets/images/aon/ccbd.png',
    url: 'https://ccbdmd.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  gwoa: {
    name: 'Georgia Women’s Oncology Associates',
    logo: '/assets/images/aon/gwoa.png',
    url: 'https://www.gwoa.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  lone: {
    name: 'Lone Star Oncology',
    logo: '/assets/images/aon/lone.png',
    url: 'https://www.lonestaronc.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  tcu: {
    name: 'Triple Crown Urology',
    logo: '/assets/images/aon/tcu.png',
    url: 'https://www.triplecrownurology.com',
    logoWidth: AON_LOGO_WIDTH,
  },
  flonc: {
    name: 'Florida Oncology',
    logo: '/assets/images/aon/flonc.png',
    url: 'https://www.floridaonc.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  bho: {
    name: 'Bay Hematology Oncology',
    logo: '/assets/images/aon/bho.png',
    url: 'https://www.bayhematologyoncology.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  cgcc: {
    name: 'Central Georgia Cancer Care',
    logo: '/assets/images/aon/cgcc.png',
    url: 'https://centralgacancercare.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
  wci: {
    name: 'Woodlands Cancer Institute',
    logo: '/assets/images/aon/wci.png',
    url: 'https://www.woodlandscancer.com/',
    logoWidth: AON_LOGO_WIDTH,
  },
}

const TC_PRACTICE_MAP: PracticeMap = {
  tcAon: {
    name: 'Thyme Care',
    logo: '/assets/images/ThymeCare_Logo_Purple.png',
    url: 'https://www.thymecare.com/',
  },
  default: {
    name: 'Thyme Care',
    logo: '/assets/images/Thyme-Logo-Wordmark-OffWhite.png',
    url: '/',
  },
}

export const ALL_PRACTICES: PracticeMap = {
  ...AON_PRACTICE_MAP,
  ...TC_PRACTICE_MAP,
}
