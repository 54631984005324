<template>
  <div class="text-nash-purple50 px-4 py-3 rounded-lg" :class="`bg-${color}`">
    <div class="flex gap-2 mb-2 items-center">
      <TIcon :icon="icon"></TIcon>
      <span id="title" class="h4 inline-block !text-white">{{ title }}</span>
    </div>
    <div id="description" class="text-nash-purple50 text-sm font-normal">
      {{ description }}
    </div>
  </div>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import { defineComponent } from 'vue'
import { MOBILE_BUTTON_PROPS } from './types'

export default defineComponent({
  components: {
    TIcon,
  },
  props: MOBILE_BUTTON_PROPS,
})
</script>
