import { VueQueryPlugin } from '@tanstack/vue-query'
import { createPinia } from 'pinia'
import PrimeVue from 'primevue/config'
import { createApp } from 'vue'
import App from '@/App.vue'

import '@/scss/app.scss'

import { auth0 } from '@/libs/state/auth0Client'
import { loadAppConfig } from './libs/config'
import { initializeDatadog } from './libs/datadog'
import { initializeLogger } from './libs/logging'
import { router } from './router'
import { APP_CONFIG_KEY } from './types/config'

const app = createApp(App)

// Top-level await is not allowed in older browsers.
loadAppConfig()
  .then((config) => {
    app.provide(APP_CONFIG_KEY, config)
    initializeLogger(config)
    initializeDatadog(config)

    app.use(auth0(config))
    app.use(VueQueryPlugin, {
      enableDevtoolsV6Plugin: true,
    })
    app.use(router)
    app.use(createPinia())
    app.use(PrimeVue)

    app.mount('#app')

    return app
  })
  .catch(console.error)
