import { NavBarInfo } from '@/types/generic/nav'
import { ALL_PRACTICES } from '@/types/practices'
import type { PropType } from 'vue'

export const DEFAULT_BODY_CLASSES = ['bg-nash-wheat500', 'text-nash-purple600']
export const DEFAULT_HEADER_CLASSES = [
  'bg-nash-purple600',
  'text-nash-wheat500',
]

export const NAV_PAGE_PROPS = {
  navBarInfo: {
    type: Object as PropType<NavBarInfo>,
    default: ALL_PRACTICES.default,
  },
  headerClasses: {
    type: Array,
    default: () => DEFAULT_HEADER_CLASSES,
  },
  bodyClasses: {
    type: Array,
    default: () => DEFAULT_BODY_CLASSES,
  },
  useContentPadding: {
    type: Boolean,
    default: true,
  },
  limitMaxWidth: {
    type: Boolean,
    default: true,
  },
  showAuthedPages: {
    type: Boolean,
    default: true,
  },
}
