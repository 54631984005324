<template>
  <TOmniLink
    :classes="['no-underline', 'font-medium', 'text-nash-purple500']"
    :link="link"
  >
    <div class="flex justify-between">
      <span>{{ title }}</span>
      <TIcon icon="heroicons:arrow-small-right-20-solid" />
    </div>
  </TOmniLink>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import TOmniLink from '@nashville/omnilink/TOmniLink.vue'
import { IconPosition } from '@thyme/nashville/src/types/icons'
import { defineComponent } from 'vue'
import { TM_FOOTER_PROPS } from './types'

export default defineComponent({
  components: {
    TIcon,
    TOmniLink,
  },
  inheritAttrs: false,
  props: TM_FOOTER_PROPS,
  setup() {
    return { IconPosition }
  },
})
</script>
