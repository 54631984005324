import { isLeft } from 'fp-ts/Either'
import { PathReporter } from 'io-ts/PathReporter'
import { Config, ConfigDecoder } from '@/types/config'

const CONFIG_FILE_NAME = 'config.json'

class ConfigManager {
  private static instance: ConfigManager | null = null

  private constructor(readonly config: Config) {}

  static async initialize(): Promise<ConfigManager> {
    if (!ConfigManager.instance) {
      let configObj: any
      try {
        const response = await fetch(`/${CONFIG_FILE_NAME}`)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        configObj = await response.json()
      } catch (e: any) {
        throw new Error('Failed to fetch application config')
      }
      const config = ConfigManager.validateConfig(configObj)
      ConfigManager.instance = new ConfigManager(config)
    }
    return ConfigManager.instance
  }

  static getInstance(): ConfigManager {
    if (!ConfigManager.instance) {
      throw new Error('App config not loaded')
    }
    return ConfigManager.instance
  }

  private static validateConfig(arg: any): Config {
    const decoded = ConfigDecoder.decode(arg)
    if (isLeft(decoded)) {
      throw new Error(
        `Application config is not valid: ${PathReporter.report(decoded).join(
          '\n'
        )}`
      )
    }
    return decoded.right
  }

  static reset() {
    ConfigManager.instance = null
  }
}

/**
 * Loads application config, throwing an error if the config is not
 * found or is not valid JSON matching the Config interface.
 */
export async function loadAppConfig(): Promise<Config> {
  const configManager = await ConfigManager.initialize()
  return configManager.config
}

/**
 *
 */
export function getAppConfig(): Config {
  return ConfigManager.getInstance().config
}

/**
 * Resets the application config, useful for testing.
 */
export function resetAppConfig() {
  ConfigManager.reset()
}
