import { useAuth0 } from '@auth0/auth0-vue'
import { unref, watch } from 'vue'
import { useRouter } from 'vue-router'

/**
 *
 */
export function setup() {
  const client = useAuth0()
  const router = useRouter()

  watch(client.isLoading, handleRedirect, { immediate: true })

  /**
   * redirect to home after auth0 callback
   */
  async function handleRedirect() {
    if (!unref(client.isLoading)) {
      // the auth0 sdk doesn't cleanly handle redirecting to
      // the original path, so we do it here explicitly once
      // the client is loaded
      const result = await client.handleRedirectCallback()
      await router.replace(result.appState?.target ?? '/')
    }
  }
}
