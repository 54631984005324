<template>
  <LoadingScreen v-if="isLoading" />
  <RouterView v-else></RouterView>
</template>

<script lang="ts">
import { useAuth0 } from '@auth0/auth0-vue'
import { defineComponent, computed, watch } from 'vue'
import { RouterView, useRoute, useRouter } from 'vue-router'
import LoadingScreen from '@/components/LoadingScreen/LoadingScreen.vue'
import { useSplitAuth } from '@/libs/splitAuth'
import { getLogger } from './libs/logging'

export default defineComponent({
  components: {
    RouterView,
    LoadingScreen,
  },
  setup() {
    useSplitAuth()

    const auth0 = useAuth0()
    const route = useRoute()
    const router = useRouter()

    // App is 'loading' until either the silent sign in finishes or they are
    // redirected to the login page.
    const isLoading = computed(() => {
      return !(route.meta.noAuthRequired || auth0.isAuthenticated.value)
    })

    watch(
      auth0.error,
      async (error) => {
        if (error) {
          getLogger().error('Auth0 error occurred', error)
          await router.push({ name: '#error' })
        }
      },
      {
        immediate: true,
      }
    )

    return {
      isLoading,
    }
  },
})
</script>
