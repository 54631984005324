import { computed, type ExtractPropTypes } from 'vue'
import { SEARCH_HIGHLIGHT_PROPS } from './types'

/**
 *
 * @param props
 */
export function setup(props: ExtractPropTypes<typeof SEARCH_HIGHLIGHT_PROPS>) {
  /**
   * Split the text by the highlight marker
   * @param text
   */
  function splitTextByHighlight(text: string) {
    return text.split(props.splitMarker)
  }

  const splitLabel = computed(() => {
    if (!props.option || !props.attribute) {
      return []
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const highlightAttr = props.option._highlightResult[props.attribute].value
    const split = splitTextByHighlight(highlightAttr)
    return split
  })

  return {
    splitLabel,
  }
}
