<template>
  <!-- This page will be quickly redirected from, so shouldn't have content -->
  <main id="content-wrapper"></main>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { setup } from './controller'

export default defineComponent({
  setup,
})
</script>
