// Enums
export enum MonitoringEventName {
  HOME_BUTTON_CLICK = 'homeButtonClick',
  CONTACT_BUTTON_CLICK = 'contactButtonClick',
  RESOURCE_ARTICLE_VIEWED = 'resourceArticleViewed',
  RESOURCE_ARTICLE_TIME = 'resourceArticleTime',
  LOGIN = 'login',
  COMMUNITY_RESOURCES_SEARCH = 'communityResourcesSearch',
}

// Types
export type HomeButtonMonitoringEvent = {
  event: MonitoringEventName.HOME_BUTTON_CLICK
  data: {
    button: string
  }
}

export type ContactButtonMonitoringEvent = {
  event: MonitoringEventName.CONTACT_BUTTON_CLICK
  data: {
    method: string
  }
}

export type ResourceArticleMonitoringEvent = {
  event: MonitoringEventName.RESOURCE_ARTICLE_VIEWED
  data: {
    slug: string
  }
}
export type ResourceArticleTimeMonitoringEvent = {
  event: MonitoringEventName.RESOURCE_ARTICLE_TIME
  data: {
    slug: string
    timeElapsedSeconds: number
  }
}

export type LoginMonitoringEvent = {
  event: MonitoringEventName.LOGIN
  data: {
    target: string
  }
}

export type CommunityResourceSearchEvent = {
  event: MonitoringEventName.COMMUNITY_RESOURCES_SEARCH
  data: {
    zipCode: string
  }
}

export type CommonMonitoringEventData = {
  entityId?: string
}

// Combined type
export type MonitoringEvent =
  | HomeButtonMonitoringEvent
  | ContactButtonMonitoringEvent
  | ResourceArticleMonitoringEvent
  | ResourceArticleTimeMonitoringEvent
  | LoginMonitoringEvent
  | CommunityResourceSearchEvent
