import { useQuery } from '@tanstack/vue-query'
import { ApiError } from '@thyme/libs/src/api/apiRequest'
import { HTTPStatusCode } from '@thyme/libs/src/api/types/statusCodes'
import { hoursToMs, minutesToMs } from '@thyme/libs/src/time/convert'
import { requiredInject } from '@thyme/libs/src/vue/inject'
import { MenuItem } from 'primevue/menuitem'
import {
  toRefs,
  computed,
  h,
  watch,
  ref,
  onMounted,
  onBeforeUnmount,
} from 'vue'
import { useRouter } from 'vue-router'
import {
  BlocksComponents,
  ModifiersComponents,
} from 'vue-strapi-blocks-renderer'
import { sendLoggedInMonitoringEvent } from '@/libs/events'
import { useLoggedInEntityId } from '@/libs/state/auth0Client'
import { lookupArticle } from '@/queries/cms/lookupArticle'

import { APP_CONFIG_KEY, Config } from '@/types/config'
import { MonitoringEventName } from '@/types/events'
import { LIBRARY_ARTICLE_PAGE_PROPS } from './types'
import type { ExtractPropTypes, Ref, VNode } from 'vue'

const ORDERED_LIST_FORMAT = 'ordered'
const NEW_TAB_LINK_ATTRS = {
  target: '_blank',
  rel: 'noopener noreferrer',
}
const INTERVAL_DURATION_MS = 30_000
const TOTAL_DURATION_MINUTES = 10
const MAX_NUMBER_OF_SENT_EVENTS =
  minutesToMs(TOTAL_DURATION_MINUTES) / INTERVAL_DURATION_MS

const SECONDS_MULTIPLIER = 30
// Merged style classes for specific block types
export const userBlocks: Partial<BlocksComponents> = {
  paragraph: ({ children }: { children?: VNode }) =>
    h(
      'p',
      {
        class: 'my-2',
      },
      children
    ),
  heading: ({ level, children }: { level: number; children?: VNode }) =>
    h(
      `h${level}`,
      {
        class: [
          level === 1 ? 'text-3xl' : '',
          level > 1 ? 'font-normal' : '',
          'text-nash-purple600',
        ],
      },
      children
    ),
  list: ({ format, children }: { format: string; children?: VNode }) =>
    h(
      format === ORDERED_LIST_FORMAT ? 'ol' : 'ul',
      {
        class: [
          format === ORDERED_LIST_FORMAT ? 'list-decimal' : 'list-disc',
          'py-1 pl-8',
        ],
      },
      children
    ),
  link: ({ url, children }: { url: string; children?: VNode }) =>
    h(
      'a',
      {
        href: url,
        ...(url.includes(window.location.host) ? {} : NEW_TAB_LINK_ATTRS),
      },
      children
    ),
}

// Placeholder to make it easier if/when we need to style
// the text styling of the blocks (bold, italics, etc.)
export const userModifiers: Partial<ModifiersComponents> = {}

/**
 * event tracking load article
 * @param config
 * @param resourceSlug
 * @param entityId
 */
function loadArticle(config: Config, resourceSlug: string, entityId: string) {
  sendLoggedInMonitoringEvent(
    {
      event: MonitoringEventName.RESOURCE_ARTICLE_VIEWED,
      data: {
        slug: resourceSlug,
      },
    },
    entityId
  )
  return lookupArticle(config, resourceSlug)
}
/**
 *
 * @param eventSendingInterval
 * @param eventSendingInterval.value
 */
function stopSendingEvents(eventSendingInterval: { value: number | null }) {
  if (eventSendingInterval.value !== null) {
    window.clearInterval(eventSendingInterval.value)
  }
}

type LibraryArticlePageProps = ExtractPropTypes<
  typeof LIBRARY_ARTICLE_PAGE_PROPS
>

/**
 *
 * @param props
 * @param props.resourceSlug
 */
export function setup(props: LibraryArticlePageProps): {
  breadcrumbs: Ref<MenuItem[]>
  title: Ref<string>
  body: Ref<any>
  userBlocks: Partial<BlocksComponents>
  userModifiers: Partial<ModifiersComponents>
} {
  const router = useRouter()
  const config = requiredInject(APP_CONFIG_KEY)
  const { resourceSlug } = toRefs(props)
  const entityId = useLoggedInEntityId()
  const eventSendingInterval = ref<number | null>(null)
  const articleSlug = ref<string>(props.resourceSlug)
  let count = 0
  const { data: article, error } = useQuery({
    queryKey: ['article', resourceSlug],
    queryFn: () => loadArticle(config, resourceSlug.value, entityId),
    retry: false,
    staleTime: hoursToMs(1),
  })

  watch(error, async (e) => {
    if (e) {
      if (e instanceof ApiError) {
        if (e.status === HTTPStatusCode.ERROR_404_NOT_FOUND) {
          await router.replace({ name: '#notFound' })
          return
        }
      }
      throw e
    }
  })

  const breadcrumbs = computed(() => {
    return [
      { label: 'Home', to: { name: '#home' } },
      { label: 'Library', to: { name: '#library' } },
      {
        label:
          article.value?.attributes.resource_category?.data.attributes.name,
      },
    ]
  })

  const title = computed(() => article.value?.attributes.title ?? '')
  const body = computed(() => article.value?.attributes.body ?? '')
  watch(
    title,
    (newTitle) => {
      document.title = newTitle
    },
    { immediate: true }
  )
  onMounted(() => {
    sendLoggedInMonitoringEvent(
      {
        event: MonitoringEventName.RESOURCE_ARTICLE_TIME,
        data: {
          slug: articleSlug.value,
          timeElapsedSeconds: count * SECONDS_MULTIPLIER,
        },
      },
      entityId
    )
    count++
    eventSendingInterval.value = window.setInterval(() => {
      if (count < MAX_NUMBER_OF_SENT_EVENTS) {
        sendLoggedInMonitoringEvent(
          {
            event: MonitoringEventName.RESOURCE_ARTICLE_TIME,
            data: {
              slug: articleSlug.value,
              timeElapsedSeconds: count * SECONDS_MULTIPLIER,
            },
          },
          entityId
        )
        count++
      } else {
        stopSendingEvents(eventSendingInterval)
      }
    }, INTERVAL_DURATION_MS)
  })

  onBeforeUnmount(() => {
    stopSendingEvents(eventSendingInterval)
  })

  return {
    title,
    body,
    breadcrumbs,
    userModifiers,
    userBlocks,
  }
}
