import { ApiError } from '@thyme/libs/src/api/apiRequest'
import { HTTPStatusCode } from '@thyme/libs/src/api/types/statusCodes'
import { getLogger } from '@/libs/logging'
import { strapiListApiRequest } from '@/queries/cms/api'
import { Config } from '@/types/config'
import {
  Locale,
  PublicationState,
  PaginationResponseMeta,
} from '@/types/strapi'

export type Article = {
  id: number
  attributes: {
    title: string
    slug: string
    body: any[]
    resource_category?: {
      data: {
        attributes: {
          name: string
        }
      }
    }
  }
}

export type LookupArticlesResponse = {
  data: Article[]
  meta: PaginationResponseMeta
}

/**
 * Gets content of article
 * Returns null otherwise.
 * @param config
 * @param slug
 */
export async function lookupArticle(
  config: Config,
  slug: string
): Promise<Article> {
  const resp = (await strapiListApiRequest(config, '/articles', {
    params: {
      pagination: { pageSize: 1, page: 1 },
      locale: [Locale.ENGLISH],
      publicationState: PublicationState.LIVE,
      fields: ['title', 'body'],
      filters: { slug: { $eq: slug } },
      populate: {
        resource_category: {
          fields: ['name'],
        },
      },
    },
  })) as LookupArticlesResponse
  if (resp.meta.pagination.total === 0) {
    throw new ApiError(
      'Article not found at slug ' + slug,
      HTTPStatusCode.ERROR_404_NOT_FOUND
    )
  }
  if (resp.meta.pagination.total > 1) {
    getLogger().warn('Multiple articles found at slug', { slug })
  }
  return resp.data[0]
}
