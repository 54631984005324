<template>
  <SurveyPage
    :nav-bar-info="practiceMap"
    :header-classes="AON_THEME_CLASSES"
    :body-classes="AON_THEME_CLASSES"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SurveyPage from '../SurveyPage/SurveyPage.vue'
import { setup } from './controller'

export default defineComponent({
  components: {
    SurveyPage,
  },
  setup,
})
</script>

<style lang="scss" scoped>
:deep(#not-found-header) {
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  color: #006496;
}
:deep(#not-found-body) {
  color: #534e5e;
}
:deep(#logout) {
  color: #006496;
}
</style>
