<template>
  <div :id="flowLabel" ref="formsortContainer" class="mt-2 block"></div>
</template>

<script lang="ts">
import { EventEmits } from '@thyme/nashville/src/types/formsort'
import { defineComponent } from 'vue'
import setup from './controller'
import type { IFormsortWebEmbedConfig } from '@formsort/web-embed-api'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'EmbeddedForm',
  props: {
    accountId: {
      type: String,
      required: true,
    },
    flowLabel: {
      type: String,
      required: true,
    },
    variantLabel: {
      type: String,
      required: true,
    },
    queryParams: {
      type: Array as PropType<Array<[string, string]>>,
      required: false,
      default: () => [],
    },
    embeddedConfig: {
      type: Object as PropType<IFormsortWebEmbedConfig>,
      required: false,
      default: () => ({}),
    },
  },
  emits: Object.values(EventEmits),
  setup,
})
</script>
