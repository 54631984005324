import type { NavBarInfo } from '@/types/generic/nav'
import type { PropType } from 'vue'

export const SURVEY_PAGE_PROPS = {
  navBarInfo: {
    type: Object as PropType<NavBarInfo>,
    default: undefined,
  },
  navThemeClasses: {
    type: Array,
    default: undefined,
  },
} as const
