import type { RouterScrollBehavior } from 'vue-router'

/**
 *
 * See https://router.vuejs.org/guide/advanced/scroll-behavior
 *
 * Scroll to saved position if it exists (back/forward buttons)
 * otherwise top of the page
 * @param to
 * @param from
 * @param savedPosition
 */
export const standardScrollBehavior: RouterScrollBehavior = (
  to,
  from,
  savedPosition
) => {
  if (savedPosition) {
    return savedPosition
  } else {
    return { top: 0 }
  }
}
