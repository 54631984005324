import { type PropType } from 'vue'
import { SearchResult, SearchResultBody } from '../types'

export const SEARCH_HIGHLIGHT_PROPS = {
  option: {
    type: Object as PropType<SearchResult>,
    required: true,
  },
  splitMarker: {
    type: String,
    default: '__MARK__',
  },
  attribute: {
    type: String as PropType<keyof SearchResultBody>,
    required: true,
  },
}
