<template>
  <NavPage>
    <h1>Community Resources</h1>
    <p class="my-5">
      Thyme Care's directory of community resources is an extension of our team.
      It can help you find organizations to help with a wide range of needs,
      from support groups and community exercise programs to transportation and
      food assistance.
    </p>
    <TInputText
      v-model="zipCode"
      :name="ZIP_CODE_FIELD_ID"
      placeholder="Zip code"
      label="Search by your zip code to get started:"
    />
    <TMPrimaryButton
      :icon="IconifyIcons.search"
      name="submit-search"
      label="Search for resources near me"
      class="mb-5 mt-2"
      :expanded="true"
      :disabled="!zipCode"
      @click="onSubmit"
    />
    <p class="italic">
      If you would like help finding resources or you are having trouble
      navigating the directory, feel free to text or call Thyme Care at
      <a :href="`tel:+${THYME_PHONE_NUMBER}`">{{
        THYME_PHONE_NUMBER_DISPLAY
      }}</a>
      or email us at <a :href="`mailto:${THYME_EMAIL}`">{{ THYME_EMAIL }}</a
      >.
    </p>
  </NavPage>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import { defineComponent } from 'vue'
import NavPage from '../tokens/NavPage/NavPage.vue'
import { setup } from './controller'

export default defineComponent({
  components: {
    TInputText,
    NavPage,
    TMPrimaryButton,
  },
  setup,
})
</script>
