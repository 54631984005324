import { object, string } from 'yup'

const US_ZIP_LENGTH = 5
export const ZIP_CODE_FIELD_ID = 'zip-code'

/**
 * Schema for the CommunityResourcesPage
 */
export const schema = object({
  [ZIP_CODE_FIELD_ID]: string()
    .required('Field is required')
    .length(US_ZIP_LENGTH, `Field must be ${US_ZIP_LENGTH} digits long`)
    .matches(/^\d+$/, 'Field must be numeric'),
})
