import { useRoute } from 'vue-router'
import {
  DEFAULT_EMBEDDED_CONFIG,
  FORMSORT_ACCOUNT_ID,
  defaultQueryParams,
} from '@/libs/formsort'
import { getLogger } from '@/libs/logging'

/**
 * Decode form arguments from base64 url encoded string
 * @param encodedArgs base64 url encoded string
 */
export function decodeFormArgs(
  encodedArgs: string | undefined
): URLSearchParams {
  if (!encodedArgs) return new URLSearchParams('')
  try {
    const decoded = window.atob(decodeURIComponent(encodedArgs))
    return new URLSearchParams(decoded)
  } catch (e) {
    getLogger().error('unable to decode form_args', e)
    return new URLSearchParams('')
  }
}

/**
 *
 */
export function setup(): {
  accountId: string
  flowLabel: string | null
  variantLabel: string | null
  embeddedConfig: object
  isValidPage: boolean
  queryParams: Array<[string, string]>
} {
  const route = useRoute()

  /**
   * Decode arguments then extract flow_id and variant
   * Pass to Component as props
   * @param encodedArgs
   */
  function splitFormArgs(encodedArgs: string | undefined) {
    const urlParamEntries = decodeFormArgs(encodedArgs)
    const flowLabel = urlParamEntries.get('flow_id')
    urlParamEntries.delete('flow_id')
    const variantLabel = urlParamEntries.get('variant')
    urlParamEntries.delete('variant')
    if (!flowLabel || !variantLabel) {
      getLogger().error(
        'flow_id or variant not found in form_args.  Cannot load form'
      )
    }
    return {
      flowLabel,
      variantLabel,
      queryParams: [
        ...defaultQueryParams(),
        ...Array.from(urlParamEntries.entries()),
      ],
    }
  }

  const formArgs = splitFormArgs(route.query.form_args as string | undefined)
  const isValidPage = !!(formArgs.flowLabel && formArgs.variantLabel)

  return {
    accountId: FORMSORT_ACCOUNT_ID,
    embeddedConfig: DEFAULT_EMBEDDED_CONFIG,
    isValidPage,
    ...formArgs,
  }
}
