<template>
  <button
    v-if="showLogOut"
    id="logout"
    aria-label="Log out"
    class="flex items-center gap-2 text-nash-purple300"
    @click="logout"
  >
    <span>Log Out </span><TIcon class="inline" icon="heroicons:arrow-right" />
  </button>
</template>

<script lang="ts">
import TIcon from '@thyme/nashville/src/components/icon/TIcon.vue'
import { defineComponent } from 'vue'
import { setup } from './controller'

export default defineComponent({
  components: {
    TIcon,
  },
  setup,
})
</script>
