import { Logger as _Logger } from 'tslog'
import { Config } from '@/types/config'

const baseSettings = {
  name: 'connect',
}

const localSettings = {
  type: 'pretty',
}

const nonLocalSettings = {
  type: 'json',
  // Recommended to avoid performance hits in production
  // tslog.js.org/#/?id=hidelogpositionforproduction-default-false
  hideLogPositionForProduction: true,
}

/**
 *
 * @param config
 */
function getEnvLoggingSettings(config: Config): Record<string, any> {
  if (config.env === 'dev') {
    return localSettings
  }
  return nonLocalSettings
}

let logger: undefined | _Logger<any>

/**
 *
 * @param config
 */
export function initializeLogger(config: Config) {
  logger = new _Logger({
    ...baseSettings,
    ...getEnvLoggingSettings(config),
  })

  logger.info('Initialized logger')
  return logger
}

/**
 *
 */
export function getLogger() {
  if (logger) {
    return logger
  }
  throw new Error('Logger not initialized')
}
