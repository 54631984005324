<template>
  <nav
    id="top-nav"
    class="nav-bar-wrapper nash-drop-shadow"
    :class="additionalClasses"
  >
    <a v-if="isExternalLink" :href="url" :aria-label="name">
      <img :width="logoWidth" :src="logo" :alt="name" :href="url" />
    </a>
    <router-link v-else :to="url" :aria-label="name">
      <img :width="logoWidth" :src="logo" :alt="name" :href="url" />
    </router-link>
    <LogoutButton />
  </nav>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import LogoutButton from '@/components/tokens/LogoutButton/LogoutButton.vue'
import { NAV_BAR_PROPS } from './types'

export default defineComponent({
  components: {
    LogoutButton,
  },
  props: NAV_BAR_PROPS,
  setup(props) {
    const isExternalLink = computed(() => props.url.startsWith('http'))

    return {
      isExternalLink,
    }
  },
})
</script>

<style lang="scss" scoped>
.nav-bar-wrapper {
  @apply flex grow-0 shrink-0;
  @apply justify-between items-center;
  @apply w-full;
  @apply px-6 py-3;
  @apply z-10;
}
</style>
