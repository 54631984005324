import type { OmniLink } from '@thyme/nashville/src/types/omnilinks'
import type { PropType } from 'vue'

export const TM_FOOTER_PROPS = {
  title: {
    type: String,
    default: '',
  },
  link: {
    type: Object as PropType<OmniLink>,
    required: true,
  },
} as const
