import { useAuth0 } from '@auth0/auth0-vue'
import { requiredInject } from '@thyme/libs/src/vue/inject'
import { computed, onBeforeUnmount, watch } from 'vue'
import { useFlagStore } from '@/libs/flags'
import { APP_CONFIG_KEY } from '@/types/config'

const UNAUTHED_SPLIT_KEY = 'anonymous'

/**
 * Sets up use of the Split client that is aware of the auth state.
 * Any time there is a change in the traffic key to be used with Split,
 * the client is re-initialized, which will reactively update all flags.
 */
export function useSplitAuth() {
  const auth0 = useAuth0()
  const config = requiredInject(APP_CONFIG_KEY)

  // Computes the correct user key for Split based on the current auth state.
  // Values can be null, the current 'name' of the user, or 'anonymous' if
  // the user is not authenticated.
  const splitUserKey = computed(() => {
    if (auth0.isLoading.value) {
      return null
    }
    if (!auth0.isAuthenticated.value || !auth0.user.value?.name) {
      // Allows us to have specific flag values for logged out users
      return UNAUTHED_SPLIT_KEY
    }
    return auth0.user.value.name
  })

  watch(
    splitUserKey,
    async (newVal, oldVal) => {
      if (newVal === oldVal || !newVal) {
        return
      }
      await useFlagStore().init(newVal, config.splitApiKey)
    },
    { immediate: true }
  )

  onBeforeUnmount(async () => {
    await useFlagStore().destroy()
  })
}
