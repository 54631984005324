import { ref } from 'vue'
import { useRouter } from 'vue-router'
import {
  DEFAULT_EMBEDDED_CONFIG,
  FORMSORT_ACCOUNT_ID,
  defaultQueryParams,
} from '@/libs/formsort'
import { getLogger } from '@/libs/logging'
import { useLoggedInEntityId } from '@/libs/state/auth0Client'
import type { Ref } from 'vue'

/**
 *
 */
export function setup(): {
  accountId: string
  flowLabel: string
  variantLabel: string
  embeddedConfig: object
  showRedirectButton: Ref<boolean>
  queryParams: Array<[string, string]>
} {
  const router = useRouter()
  const FLOW_LABEL = 'symptom-identification'
  const VARIANT_LABEL = 'tcconnect'

  const showRedirectButton = ref(false)

  const queryParams = defaultQueryParams()
  try {
    queryParams.push(['entity_id', useLoggedInEntityId()])
  } catch (e: any) {
    getLogger().error("Could not get entity_id from user's metadata", e)
    void router.push({ name: '#error' })
  }

  return {
    accountId: FORMSORT_ACCOUNT_ID,
    flowLabel: FLOW_LABEL,
    variantLabel: VARIANT_LABEL,
    embeddedConfig: DEFAULT_EMBEDDED_CONFIG,
    queryParams,
    showRedirectButton,
  }
}
