import { AllowedIcons } from '@thyme/nashville/src/types/icons'
import type { LinkedMobileButtonAttrs } from '@/components/tokens/LinkedMobileButton/types'
import { sendLoggedInMonitoringEvent } from '@/libs/events'
import { useLoggedInEntityId } from '@/libs/state/auth0Client'
import { THYME_PHONE_NUMBER_DISPLAY } from '@/libs/thymeInfo'
import { MonitoringEventName } from '@/types/events'
import { useWhoAmI } from './queries'

/**
 *
 */
export function setup(): {
  logo: string
  buttons: LinkedMobileButtonAttrs[]
  onHomeButtonClick: (title: string) => void
  AllowedIcons: typeof AllowedIcons
} {
  const entityId = useLoggedInEntityId()

  useWhoAmI(entityId)

  /**
   * event track home button click
   * @param title
   */
  function onHomeButtonClick(title: string) {
    sendLoggedInMonitoringEvent(
      {
        event: MonitoringEventName.HOME_BUTTON_CLICK,
        data: {
          button: title,
        },
      },
      entityId
    )
  }

  return {
    AllowedIcons,
    logo: '/assets/images/Thyme-Logo-Wordmark-OffWhite.png',
    onHomeButtonClick,
    buttons: [
      {
        color: 'nash-purple500',
        icon: AllowedIcons.phone,
        title: 'Get support 24/7',
        description: `Call ${THYME_PHONE_NUMBER_DISPLAY} to speak with an oncology nurse or clinician, or text your care team with questions or to set up time to talk.`,
        link: { to: { name: '#support' } },
      },
      {
        color: 'nash-teal700',
        icon: AllowedIcons.pencilSquare,
        title: 'Track Symptoms',
        description:
          'Complete a quick questionnaire about your current symptoms to let your Care Team know how you’re doing.',
        link: { to: { name: '#symptoms' } },
      },
      {
        color: 'nash-midnightBlue700',
        icon: AllowedIcons.bookOutline,
        title: 'Library',
        description:
          'Read about your diagnosis and treatment options, being a cancer patient, and wellness during and after treatment.',
        link: { to: { name: '#library' } },
      },
      {
        color: 'nash-purple600',
        icon: AllowedIcons.buildingLibrary,
        title: 'Community Resources',
        description: 'Search our directory for community resources near you.',
        link: { to: { name: '#communityResources' } },
      },
    ],
  }
}
